<template>
  <v-container>
    <div class="breve">
      <div class="section_em_breve">
        <div class="name">
          <img
            src="./../../public/imgs/name.png"
            alt="icone com o nome Sugai e Waux"
          />
        </div>
        <div class="efeito">
          <img
            src="./../../public/imgs/veiculo.png"
            alt="Um veiculo amarelo em alta velocidade"
          />
        </div>
        <div class="em_breve">
          <div>
            <img
              src="./../../public/imgs/em_breve.png"
              alt="Dizeres Em Breve"
            />
          </div>
          <div class="novo_site">
            <img
              src="./../../public/imgs/novo_site.png"
              alt="Dizeres UM NOVO SITE"
            />
          </div>
        </div>
        <!-- <h2 class="parceiro_txt">Nossos parceiros</h2> -->
        <br />
        <div class="parceiros">
          <!-- <div class="parceiro">
            <img
              src="./../../public/imgs/parceiros/parceiro_1.png"
              alt="logotipo do parceiro totalEnergies"
            />
          </div>
          <div class="parceiro">
            <img
              src="./../../public/imgs/parceiros/parceiro_2.png"
              alt="logotipo do parceiro elf"
            />
          </div> -->
          <!-- <div class="parceiro">
            <img
              src="./../../public/imgs/parceiros/parceiro_3.png"
              alt="logotipo do parceiro FUCHS"
            />
          </div>
          <div class="parceiro">
            <img
              src="./../../public/imgs/parceiros/parceiro_4.png"
              alt="logotipo do parceiro silkolene"
            />
          </div> -->
        </div>
      </div>
      <div class="section_fantasma"></div>
      <div class="section_contato">
        <div class="contato">
          <div class="icone_contato">
            <img
              src="./../../public/imgs/icones/icone_1.png"
              alt="icone telefone"
            />
            &nbsp; &nbsp; &nbsp;
            <h6>Contato</h6>
          </div>
          <p class="contato_info">11 4652-1450</p>
          <p class="contato_info">11 2085-7810</p>
          <br />

          <div class="icone_contato">
            <img
              src="./../../public/imgs/icones/icone_2.png"
              alt="icone e-mail"
            />
            &nbsp; &nbsp; &nbsp;
            <h6>E-mail</h6>
          </div>
          <p class="contato_info">contato@sugaiwaux.com.br</p>
          <br />
          <div class="icone_contato">
            <img
              src="./../../public/imgs/icones/icone_3.png"
              alt="icone endereço"
            />
            &nbsp; &nbsp; &nbsp;
            <h6>Endereço</h6>
          </div>
          <h6>São Paulo</h6>
          <p class="contato_info">R. Araucária, 108 - Água Chata</p>
          <p class="contato_info">Guarulhos - SP, 07251-050</p>
          <br />
          <h6>Mato Grosso Do Sul</h6>
          <p class="contato_info">R. Ari Coelho De Oliveira, 337 - Vila</p>
          <p class="contato_info">Progresso - Campo Grande - MS,</p>
          <p class="contato_info">79050-310</p>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'HomePage',
  props: {
    msg: String,
  },
};
</script>
<style scoped>
.breve {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}
.name {
  margin-top: 25px;
}
.section_em_breve {
  width: 695px;
}
.efeito {
  position: absolute;
  margin: -25px 0px 0 -125px;
  animation-name: veiculo_movimento;
  animation-duration: 3s;
}
@keyframes veiculo_movimento {
  from {
    margin: -25px 0 0 -1000px;
  }
  to {
    margin: -25px 0px 0 -125px;
  }
}
.em_breve {
  margin: 100px 0 50px 0;
  display: flex;
  align-items: baseline;
}
.novo_site {
  margin: 0 0 0 25px;
  padding: 15px 0 0 0;
}

.parceiros {
  display: flex;
  /* padding: 25px 0; */
  /* justify-content: space-between; */
  justify-content: space-around;
  align-items: center;
}
.parceiro_txt {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 28px;
  color: #ffffff;
}
.parceiro {
  margin: 0 15px;
}
.section_fantasma {
  position: absolute;
  z-index: 0;
  right: 0;
  /* min-width: 450px; */
  width: 35vw;
  height: 510px;
  transition: 1s;
  background-color: #0d2b96c4;
}
.section_contato {
  position: relative;
  z-index: 2;
  /* width: 320px; */
  height: 510px;
  /* background-color: #0d2b96c4; */
  /* background-image: url('./../../public/imgs/bg_contato.png');
  background-repeat: no-repeat;
  background-size: cover; */
}
.contato {
  padding: 25px;
  /* background-color: aquamarine; */
}
.icone_contato {
  display: flex;
  align-items: center;
}

h6 {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 28px;
  color: #ffffff;
}
.contato_info {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 100;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  padding: 0;
  margin: 0;
  flex: none;
  order: 1;
  flex-grow: 0;
}
@media screen and (orientation: portrait), (max-width: 1000px) {
  .breve {
    flex-direction: column;
    height: auto;
  }
  .section_fantasma {
    display: none;
  }
  .section_em_breve {
    width: 90vw;
    text-align: center;
  }
  .em_breve {
    justify-content: center;
  }
  .efeito {
    margin: 3vh 0px 0 0;
  }
  .efeito img {
    width: 130vw;
  }
  @keyframes veiculo_movimento {
    from {
      margin: -25px 0 0 -1000px;
    }
    to {
      margin: 3vh 0px 0 0;
    }
  }
  .name img {
    width: 80vw;
  }

  .em_breve div img,
  .novo_site img {
    width: 40vw;
  }
  .parceiros {
    flex-direction: column;
  }
  .parceiro img {
    margin: 15px 0;
    max-width: 40vw;
  }
}
@media screen and (orientation: portrait), (width: 430px) {
  .efeito {
    margin: 8vh 0px 0 0;
  }

  @keyframes veiculo_movimento {
    from {
      margin: -25px 0 0 -1000px;
    }
    to {
      margin: 8vh 0px 0 0;
    }
  }
}
</style>
